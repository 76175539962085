import React from "react"
import Layout from "../components/common/layout/layout"
import PrivateContent from "../components/user/privatecontent"

import SIBCheckout from "../components/commerce/checkout"

const CheckoutContainer = (props) => {

  var productState =
    props.location && props.location.state && props.location.state.productSelect
      ? props.location.state.productSelect
      : "singleuser"

  return (
    <Layout>
      <PrivateContent accessLevel="requireslogin">
        <SIBCheckout product={productState} />
      </PrivateContent>
    </Layout>
  )
}

export default CheckoutContainer
