import React, { useState, useEffect } from "react"
import PageBanner from "../common/misc/pagebanner"
import { useIdentityContext } from "../../services/react-netlify-identity-gotrue"
import { useSubInfoContext } from "../../services/subinfocontext"
import { Link, navigate } from "gatsby"

import { loadStripe } from "@stripe/stripe-js"
import Loader from "react-loader-spinner"

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
  }
  return stripePromise
}

const SIBCheckout = (props) => {
  const identity = useIdentityContext()
  const subInfoContext = useSubInfoContext()

  const [loading, setLoading] = useState(false)
  const [isAlreadySubbed, setAlreadySubbed] = useState(false)

  // get students out of here
  useEffect(() => {
    if (subInfoContext.subInfo) {
      if (subInfoContext.subInfo.isStudentCode) {
        navigate("/studenthome")
      }
    }
  }, [subInfoContext.subInfo, identity.user])

  // and users once logged in
  useEffect(() => {
    if (subInfoContext.subInfo) {
      if (identity.user) {
        if (
          !subInfoContext.subInfo.isUnpaid &&
          !subInfoContext.subInfo.isExpired
        ) {
          setAlreadySubbed(true)
        }
      }
    }
  }, [subInfoContext.subInfo, identity.user])

  const redirectToCheckout = async (event, product) => {
    event.preventDefault()
    setLoading(true)

    const singlePriceId = process.env.GATSBY_SIB_SINGLE_PROD_ID
    const multiPriceId = process.env.GATSBY_SIB_CLASS_PROD_ID

    if (!identity.user || !subInfoContext.subInfo) {
      console.warn("NO USER")
      setLoading(false)
      return
    }

    var lineItems = [{ price: singlePriceId, quantity: 1 }]
    if (product === "singleclass") {
      lineItems = [{ price: multiPriceId, quantity: 1 }]
    }

    var successUrlAffix = "?single"
    if (product === "singleclass") {
      successUrlAffix = "?class"
    }

    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      customerEmail: identity.user.email,
      clientReferenceId: `${product}|${identity.user.id}`,
      lineItems,
      successUrl: `${process.env.GATSBY_NETLIFY_IDENTITY_SITE}/checkout-success${successUrlAffix}`,
      cancelUrl: `${process.env.GATSBY_NETLIFY_IDENTITY_SITE}/checkout`,
    })

    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }

  var userGreet = "Welcome!"

  if (props.subInfo) {
    userGreet = props.subInfo.userGreet
  }

  return (
    <>
      <PageBanner title="Subscription Purchase" userGreet={userGreet} />
      <div className={`py-1 ${loading ? "" : "is-hidden"}`}>
        <div className="has-text-centered my-2">
          <Loader type="TailSpin" color="#000000" height={100} width={100} />
        </div>
        <div className="title is-5 has-text-centered mt-3">
          Preparing Transaction - Please Wait...
        </div>
      </div>
      <div className={`${loading ? "is-hidden" : ""}`}>
        <div className={`${isAlreadySubbed ? "" : "is-hidden"}`}>
          <div className="container has-text-centered pt-6">
            <p>
              You are already subscribed to Shakespeare In Bits! For
              subscription details, see your
              <Link to="/app/profile" className="has-text-sibredorange">
                {" "}
                account page.
              </Link>
            </p>
            <p>
              If you'd like to make changes to your subscription, plase feel
              free to{" "}
              <Link to="/contact" className="has-text-sibredorange">
                Contact us!
              </Link>
            </p>
            <div className="block py-5">
              <Link className="button is-black" to="/app/userhome">
                Continue
              </Link>
            </div>
          </div>
        </div>
        <div className={`${isAlreadySubbed ? "is-hidden" : ""}`}>
          <div className="container has-text-centered pt-6">
            <p className="block">You're nearly there!</p>
            <p className="block">
              Press 'Continue' below to purchase the following product:
            </p>
            <p
              className={`block has-text-weight-bold ${
                props.product === "singleuser" ? "" : "is-hidden"
              }`}
            >
              Shakespeare In Bits - 1 Year Subscription - Single User
            </p>
            <p
              className={`block has-text-weight-bold ${
                props.product === "singleclass" ? "" : "is-hidden"
              }`}
            >
              Shakespeare In Bits - 1 Year Subscription - Single Class up to 30
              Students
            </p>
            <div className="block py-5">
              <div
                role="button"
                tabIndex={0}
                className="button is-black"
                onClick={(e) => redirectToCheckout(e, props.product)}
                onKeyDown={(e) => redirectToCheckout(e, props.product)}
              >
                Continue to Purchase
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SIBCheckout
